/* make text selectable on an Ionic app */
/* (this is absurd) */
* {
  -webkit-user-select: text;
  -moz-user-select: text;
  -ms-user-select: text;
  user-select: text;
}

.ion-page {
  background-color: white;
}

.footer-ios {
  background-color: white;
}

.no-padding {
  padding: unset;
}

.hide {
  display: none;
}

.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.text-break-line {
  white-space: pre-wrap !important;
}
